import { isPlatformBrowser } from '@angular/common';
import { Component, inject, OnInit, PLATFORM_ID, ViewEncapsulation } from '@angular/core';
import { MetaService } from '../../../../../shared/src/lib/services/meta.service';

@Component({
  selector: 'app-not-found',
  standalone: true,
  imports: [],
  templateUrl: './not-found.component.html',  
  styleUrl: './not-found.component.css'
})
export class NotFoundComponent implements OnInit {
  private readonly platformId = inject(PLATFORM_ID);
  private readonly metaService = inject(MetaService);

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      document.getElementById('main').classList.remove('d-none');
      document.getElementById('loader').classList.add('d-none');
    }

    this.metaService.resetMetaTags();
  }
}
