import { APP_INITIALIZER, ApplicationConfig, importProvidersFrom, inject, PLATFORM_ID } from '@angular/core';
import { provideRouter, withComponentInputBinding } from '@angular/router';

import { routes } from './app.routes';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';

import { HttpClient, HttpClientModule, provideHttpClient, withFetch, withInterceptors } from '@angular/common/http';
import { BASE_PATH } from '../openapi';
import { provideAngularSvgIcon } from 'angular-svg-icon';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideToastr } from 'ngx-toastr';
import { BlockUIModule } from 'ng-block-ui';
import { BlockUIHttpModule } from 'ng-block-ui/http';
import { siteKeyInterceptor } from './common/SiteKeyInterceptor.service';
import { QuillModule } from 'ngx-quill';
import { ConfigService } from '../../../shared/src/lib/services/config.service';
// import { HttpLoaderFactory, initializeApp } from '../../../shared/src/lib/shared-function';
import { CustomHttpLoaderFactory, initializeApp } from '../../../shared/src/lib/shared-function';
import { CACHED_LOGO, CAPTCHA_KEY } from '../../../shared/src/lib/injectable-tokens';
//import { NGX_GOOGLE_ANALYTICS_SETTINGS_TOKEN, NgxGoogleAnalyticsModule } from 'ngx-google-analytics';
import { provideClientHydration } from '@angular/platform-browser';
import { trasferStateInterceptor } from './common/TransferStateInterceptor.service';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { Location } from '@angular/common';
import { LocalizeParser, LocalizeRouterSettings, ManualParserLoader, withLocalizeRouter } from '@gilsdav/ngx-translate-router';
import { localizeLoaderFactory } from './common/localize-loader-factory';
import { Constants } from '../../../shared/src/lib/constants';

const DEFAULT_LANG = Constants.Language.Arabic

export const appConfig: ApplicationConfig = {
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [ConfigService],
      multi: true,
    },
    provideRouter(
      routes,
      withComponentInputBinding(), 
      withLocalizeRouter(routes, {
        parser: {
          provide: LocalizeParser,
          useFactory: localizeLoaderFactory,
          deps: [TranslateService, Location, LocalizeRouterSettings]
        },
        defaultLangFunction: () => DEFAULT_LANG,
        initialNavigation: true,
      })
    ),
    provideHttpClient(withFetch(), withInterceptors([trasferStateInterceptor, siteKeyInterceptor])),
    provideAnimations(),
    provideAngularSvgIcon(),
    provideToastr({
      toastClass: 'toastr-style ngx-toastr',
      positionClass: 'toast-top-center',
      progressBar: true,
      progressAnimation: 'increasing',
      timeOut: 5000
    }),
    {
      provide: BASE_PATH,
      useFactory: (configService: ConfigService) => getConfigApi(configService),
      deps: [ConfigService],
    },    
    {
      provide: CAPTCHA_KEY,
      useFactory: (configService: ConfigService) => configService.getCaptchaKey(),
      deps: [ConfigService],
    },
    {
      provide: CACHED_LOGO,
      useFactory: (configService: ConfigService) => configService.getCachedLogo(),
      deps: [ConfigService],
    },
    //NgxGoogleAnalyticsModule.forRoot('', []).providers!,
    importProvidersFrom([
      TranslateModule.forRoot({
        defaultLanguage: DEFAULT_LANG,
        loader: {
          provide: TranslateLoader,
          useFactory: CustomHttpLoaderFactory,
          deps: [HttpClient]
        }
      }),
      QuillModule.forRoot({
        modules: {
          syntax: false,
          toolbar: [
            ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
            ['blockquote', 'code-block'],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            [{ 'direction': 'rtl' }],
            ['clean'],
            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
          ]
        }
      }),
      HttpClientModule,
      BlockUIModule.forRoot({
        delayStart: 1,
        delayStop: 500
      }),
      BlockUIHttpModule.forRoot({
        blockAllRequestsInProgress: true
      }),
    ]), provideClientHydration(),
  ]
};

export const getConfigApi = (configService: ConfigService): string => {
  const platformId = inject(PLATFORM_ID);

  if (isPlatformBrowser(platformId)) {
    return configService.getApi();
  }

  const apiHttp = configService.getApiHttp();
  return (apiHttp && apiHttp !== '') ? apiHttp : configService.getApi();
}

