import { HttpEvent, HttpHandlerFn, HttpInterceptorFn, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { inject } from '@angular/core';
import { SiteKeyService } from './site.service';

export const siteKeyInterceptor: HttpInterceptorFn = (req: HttpRequest<any>, next: HttpHandlerFn): Observable<HttpEvent<any>> => {
  const siteKeyService = inject(SiteKeyService);

  if (!siteKeyService.extractKey())
    return next(req); 

  // Clone the request and add the "site-key" header
  const modifiedReq = req.clone({
    setHeaders: { 'site-key': siteKeyService.extractKey() }
  });
  // Pass the modified request to the next handler
  return next(modifiedReq);
};
